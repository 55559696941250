<!--
 * @Description: 
 * @Author: wendan
 * @Date: 2022-03-04 18:38:53
 * @LastEditTime: 2022-03-04 20:31:29
 * @LastEditors: liutq
 * @Reference: 
-->
<template>
  <div class="wrapper">
	  <router-view/>
  </div>
</template>

<script>
export default {
	data() {
		return {
		}
	}
}
</script>

<style lang="scss" scoped></style>